import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { getActiveOrganization, getAllUsersData } from "selectors/organizations";
import { getTextsData } from "selectors/texts";
import { useSelector } from "react-redux";
import ActivityCell from "./lib/ActivityCell";
import Constants from "const/Constants";
import Countries from "const/Countries";
import DataConstants from "const/DataConstants";
import IconCell from "./lib/IconCell";
import NameCell from "./lib/NameCell";
import React, { useCallback, useMemo, useState } from "react";
import Table, { TableHead, TableRow } from "nlib/ui/Table";
import UiRoutes from "const/UiRoutes";
import UserRoles from "const/UserRoles";
import Utils from "utils/Utils";
import classNames from "classnames";

const { LS_KEYS: { TASKS_VIEW_LAST_TAB } } = Constants;

const { STATUSES: { TO_RECONCILE, TO_REVIEW, NEED_REACTION, EXPORTED } } = DataConstants;

const BUSINESS_STATES = {
  WARNING: "warning",
  HIGHLIGHT: "highlight",
  ERROR: "error"
};

const getBusinessState = (extraData, settings) => {
  const {
    integrationService,
    lastEntitiesAutoSetAt,
    lastGptAiFineTuneStatus,
    uncategorizedRpaEnabled,
    reconciliationRpaEnabled,
    lastGptAiCategorizedAt,
    integrationServiceConnected
  } = extraData;

  const fineTuneError = lastGptAiFineTuneStatus === Constants.FINE_TUNE_STATUS_FAILED;

  const rpaMode = uncategorizedRpaEnabled !== undefined || reconciliationRpaEnabled !== undefined;

  const rpaNotEnabled = uncategorizedRpaEnabled === false || reconciliationRpaEnabled === false;

  const settingUp = uncategorizedRpaEnabled === undefined && reconciliationRpaEnabled === undefined
    ? !lastEntitiesAutoSetAt
    : !lastGptAiCategorizedAt;

  if (!integrationService) return null;

  if (!integrationServiceConnected) return BUSINESS_STATES.ERROR;

  if (
    (settingUp && (fineTuneError || rpaNotEnabled)) || (rpaMode && (settings.rpaAutoAskClient === undefined))
  ) {
    return BUSINESS_STATES.WARNING;
  }

  if (settingUp) return BUSINESS_STATES.HIGHLIGHT;

  if (rpaNotEnabled) return BUSINESS_STATES.ERROR;

  if (rpaMode) {
    return settings.rpaPaused && BUSINESS_STATES.WARNING;
  }

  return null;
};

const SummaryTable = ({ data, rpaMode }) => {
  const [{ sortBy, sortOrder }, setEnvVars] = useState({});

  const { uiTexts } = useSelector(getTextsData);

  const usersData = useSelector(getAllUsersData);

  const { countryCode } = useSelector(getActiveOrganization);

  const czCountry = countryCode === Countries.CZ;

  const tasksViewLastTab = Utils.storageValue(TASKS_VIEW_LAST_TAB) || "list";

  const sorted = useMemo(() => {
    switch (sortBy) {
      case "tasks.forBusinesses":
      case "tasks.forAccountants":
      case "transactions.exported":
        return Utils.arraySort(data, (item) => {
          const { current = 0, total = 0 } = Utils.getPropertyByPath(item, sortBy) || {};

          return total ? current / total : -1;
        }, sortOrder === "asc");
      case "lastActivityEvent":
        return Utils.arraySort(data, (item) => {
          return Utils.getPropertyByPath(item, "lastActivityEvent.timestamp") || "";
        }, sortOrder !== "asc");
      default:
        return Utils.arraySort(data, (item) => {
          return Utils.getPropertyByPath(item, sortBy);
        }, sortOrder === "asc");
    }
  }, [data, sortBy, sortOrder]);

  const handleSortChange = useCallback((sortParams) => {
    setEnvVars(sortParams);
  }, [setEnvVars]);

  return (
    <Table
      theme="flat"
      sortBy={sortBy}
      sortOrder={sortOrder}
      onSortChange={handleSortChange}
      className={Css.table}>
      <TableRow className={Css.row}>
        <TableHead
          className={`${Css.head} ${Css.nameCell}`}
          accessor="business.name">
          {uiTexts.business}
        </TableHead>
        <TableHead
          title={uiTexts.transactionsToClassify}
          className={classNames(Css.head, Css.iconCell)}
          accessor="transactions.toReconcile.current">
          {uiTexts.transactionsToClassify}
        </TableHead>
        <TableHead
          title={uiTexts.transactionsAwaitingClarification}
          className={classNames(Css.head, Css.iconCell)}
          accessor="transactions.needReaction.current">
          {uiTexts.transactionsAwaitingClarification}
        </TableHead>
        <TableHead
          title={uiTexts.transactionsToReview}
          className={classNames(Css.head, Css.iconCell)}
          accessor="transactions.toReview.current">
          {uiTexts.transactionsToReview}
        </TableHead>
        <TableHead
          title={uiTexts.documentsToReview}
          className={classNames(Css.head, Css.iconCell)}
          accessor="documents.toReview.current">
          {uiTexts.documentsToReview}
        </TableHead>
        <TableHead
          title={uiTexts.exportedTransactions}
          className={classNames(Css.head, Css.iconCell)}
          accessor="transactions.exported"
          show={czCountry}>
          {uiTexts.exportedTransactions}
        </TableHead>
        <TableHead
          title={uiTexts.tasksForAccountant}
          className={classNames(Css.head, Css.iconCell)}
          accessor="tasks.forAccountants">
          {uiTexts.tasksForAccountant}
        </TableHead>
        <TableHead
          title={uiTexts.tasksForClient}
          className={classNames(Css.head, Css.iconCell)}
          accessor="tasks.forBusinesses">
          {uiTexts.tasksForClient}
        </TableHead>
        <TableHead
          title={uiTexts.recentActivity}
          accessor="lastActivityEvent"
          className={`${Css.head} ${Css.activityCell}`}>
          {uiTexts.recentActivity}
        </TableHead>
      </TableRow>
      {sorted.map((item) => {
        const { business: { id: businessId, name, extraData = {}, settings = {} }, lastActivityEvent } = item;

        const { TRANSACTIONS, DOCUMENTS, TASKS } = UiRoutes;

        const currentBusinessHasBusinessUsers = usersData.some(({ role, businessIds }) => {
          return UserRoles.checkIsBusiness(role) && businessIds.includes(businessId);
        });

        const businessState = getBusinessState(extraData, settings);

        return (
          <TableRow
            key={businessId}
            className={classNames(
              Css.row,
              CommonCss.tableRow,
              businessState === BUSINESS_STATES.WARNING && CommonCss.warningRow,
              businessState === BUSINESS_STATES.ERROR && CommonCss.negativeRow,
              businessState === BUSINESS_STATES.HIGHLIGHT && CommonCss.highlightRow
            )}>
            <NameCell
              className={Css.nameCell}
              name={name}
              businessId={businessId}
              extraData={extraData}
              settings={settings} />
            <IconCell
              theme="secondary"
              className={Css.iconCell}
              iconComponent={Icons.Question}
              value={Utils.getPropertyByPath(item, "transactions.toReconcile.current")}
              href={`/${businessId}${TRANSACTIONS}?${Utils.objectToQueryString({ status: TO_RECONCILE })}`} />
            <IconCell
              theme="warning"
              className={Css.iconCell}
              emptyState={!rpaMode && !currentBusinessHasBusinessUsers
                && !Utils.getPropertyByPath(item, "transactions.needReaction.current")}
              iconComponent={Icons.WarningCircle}
              value={Utils.getPropertyByPath(item, "transactions.needReaction.current")}
              href={`/${businessId}${TRANSACTIONS}?${Utils.objectToQueryString({ status: NEED_REACTION })}`} />
            <IconCell
              theme="primary"
              className={Css.iconCell}
              iconComponent={Icons.Eye}
              value={Utils.getPropertyByPath(item, "transactions.toReview.current")}
              href={`/${businessId}${TRANSACTIONS}?${Utils.objectToQueryString({ status: TO_REVIEW })}`} />
            <IconCell
              theme="primary"
              className={Css.iconCell}
              iconComponent={Icons.FileText}
              value={Utils.getPropertyByPath(item, "documents.toReview.current")}
              href={`/${businessId}${DOCUMENTS}?${Utils.objectToQueryString({ status: TO_REVIEW })}`} />
            <IconCell
              progress
              theme="success"
              show={czCountry}
              className={Css.iconCell}
              iconComponent={Icons.ArrowSquareOut}
              value={Utils.getPropertyByPath(item, "transactions.exported")}
              href={`/${businessId}${TRANSACTIONS}?${Utils.objectToQueryString({ status: EXPORTED })}`} />
            <IconCell
              progress
              theme="success"
              className={Css.iconCell}
              iconComponent={Icons.ChatCircleText}
              value={Utils.getPropertyByPath(item, "tasks.forAccountants")}
              href={`/${businessId}${TASKS}/${tasksViewLastTab}?targetUser=accountant`} />
            <IconCell
              progress
              theme="accent"
              emptyState={!currentBusinessHasBusinessUsers}
              className={Css.iconCell}
              iconComponent={Icons.Lightning}
              value={Utils.getPropertyByPath(item, "tasks.forBusinesses")}
              href={`/${businessId}${TASKS}/${tasksViewLastTab}?targetUser=client`} />
            <ActivityCell
              businessId={businessId}
              lastActivityEvent={lastActivityEvent}
              className={Css.activityCell} />
          </TableRow>
        );
      })}
    </Table>
  );
};

export default React.memo(SummaryTable);
