import Css from "./style.module.scss";

import { TableCell } from "nlib/ui/Table";
import Button from "nlib/ui/Button";
import Constants from "const/Constants";
import React from "react";
import classNames from "classnames";

const { EMPTY_PLACEHOLDER } = Constants;

const IconCell = ({ show, emptyState, className, value, progress, href, theme, iconComponent: Icon }) => {
  const { current = 0, total = 0 } = (progress ? value : { current: value }) || {};

  const disabled = !current && !total;

  const content = progress ? `${current}/${total}` : current;

  return (
    <TableCell
      show={show}
      className={classNames(Css.iconCell, className, disabled && Css.noData)}>
      <div>
        {emptyState
          ? <div className={Css.emptyState}>{EMPTY_PLACEHOLDER}</div>
          : (
            <a href={disabled ? undefined : href} title={content}>
              <Button {...{ [theme]: disabled ? undefined : true }} disabled={disabled}>
                <Icon weight={disabled ? undefined : "bold"} />
              </Button>
              <div>{content}</div>
            </a>
          )}
      </div>
    </TableCell>
  );
};

export default React.memo(IconCell);
